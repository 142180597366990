















import { Vue, Component } from 'vue-property-decorator'
import { BButton, BCard } from 'bootstrap-vue'
import WebinarsTable from '@/components/webinars/WebinarsTable.vue'

@Component({
  components: { BButton, BCard, WebinarsTable },
})
export default class Webinars extends Vue {}

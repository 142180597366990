





































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { BButton, BBadge, BAvatar } from 'bootstrap-vue'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue'
import { showError, showMessage } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { IWebinarTagSimple } from '@/interfaces/IWebinarTag'
import { formatDateTime } from '@/helpers/formatters'
import { copyTextToClipboard } from '@/helpers/copy'
const { VueGoodTable } = require('vue-good-table')
import '@core/scss/vue/libs/vue-good-table.scss'

interface IWebinar {
  id: number
  title: string
  live: boolean
  tag: IWebinarTagSimple
  teacher: {
    name: string
    avatar: string
  }
  instancesCount: number
  dateStart: string
  instanceUrl: string
}

@Component({
  components: {
    BButton,
    VueGoodTable,
    BBadge,
    DeleteConfirmationModal,
    BAvatar,
  },
})
export default class WebinarsTable extends Vue {
  @Ref('deleteModal') readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }

  webinars: IWebinar[] = []
  columns: Array<{
    field: string
    label: string
    type?: string
    sortable?: boolean
    width?: string
  }> = [
    {
      field: 'title',
      label: 'Название',
      width: '100%',
    },
    {
      field: 'live',
      label: 'live',
    },
    {
      field: 'tag',
      label: 'Тег',
    },
    {
      field: 'teacher',
      label: 'Ведущий',
    },
    {
      field: 'instancesCount',
      label: 'Кол-во экземпляров',
    },
    {
      field: 'dateStart',
      label: 'Старт ближайшего',
    },
    {
      field: 'instanceUrl',
      label: 'url ближайшего',
      sortable: false,
    },
    {
      field: 'buttons',
      label: '',
      sortable: false,
    },
  ]

  get formatDateTime() {
    return formatDateTime
  }

  async mounted() {
    await this.fetchWebinars()
  }

  async fetchWebinars() {
    try {
      const response: AxiosResponse<{ webinars: IWebinar[] }> = await axios.get(
        'v1/webinars',
      )
      this.webinars = response.data.webinars
    } catch (error) {
      console.error(error)
      showError('При получении событий произошла ошибка')
    }
  }

  async remove(id: number, label: string) {
    if (await this.deleteConfirmationModal.show(label)) {
      try {
        await axios.delete(`v1/webinars/${id}`)
        this.webinars = this.webinars.filter(w => w.id !== id)
      } catch (error) {
        console.error(error)
        showError('При удалении события произошла ошибка')
      }
    }
  }

  copyUrl(url: string) {
    if (copyTextToClipboard(url)) {
      showMessage('Url успешно скопирован')
    }
  }
}

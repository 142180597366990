export function copyTextToClipboard(text: string, parent?: HTMLElement) {
  const textArea = document.createElement('textarea')
  let result = true

  textArea.style.position = 'fixed'
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.width = '2em'
  textArea.style.height = '2em'
  textArea.style.padding = '0'
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'
  textArea.style.background = 'transparent'
  textArea.value = text
  if (parent) {
    parent.appendChild(textArea)
  } else {
    document.body.appendChild(textArea)
  }
  textArea.focus()
  textArea.select()

  try {
    if (!document.execCommand('copy')) {
      result = false
    }
  } catch (error) {
    console.error(error)
    result = false
  }

  if (parent) {
    parent.removeChild(textArea)
  } else {
    document.body.removeChild(textArea)
  }

  return result
}
